import { precisTranslationDto } from '@/codices/app/data/models/precis-translation'
import { SearchTypes } from '@/codices/app/data/models/search-types.enum'
import { TaxonDto } from '@/codices/app/data/models/taxon.model'
import { ThesaurusTranslation } from '@/codices/app/data/models/thesaurus-translation.model'
import { ThesaurusDto } from '@/codices/app/data/models/thesaurus.model'
import { HighlightFacade } from '@/codices/app/features/results/services/highlight.facade'
import { LanguageTranslation } from '@/core/types/language-translation.model'
import { Injectable } from '@angular/core'
import { Store } from '@ngxs/store'
import {
  DocumentGetViewModel,
  DocumentHighlight,
} from '../../services/documents.service'
import { PrecisStateModel, PrecisViewModel } from '../state/precis.state'

@Injectable()
export class PrecisService implements DocumentGetViewModel, DocumentHighlight {
  constructor(private store: Store, private highlightFacade: HighlightFacade) {}

  getViewModel(selectedLang: string): any {
    const precisVM = new PrecisViewModel()
    const precisState = this.store.snapshot().precisState as PrecisStateModel
    const translations = new Map<string, precisTranslationDto>(
      Object.entries(precisState.precis.precisTranslations),
    )
    let precisTranslated = translations.get(selectedLang)
    precisVM.precis = precisState.precis
    const firstTrans: [string, precisTranslationDto] | undefined = translations
      .entries()
      .next().value

    if (!precisTranslated && firstTrans) {
      precisTranslated = firstTrans[1]
      selectedLang = firstTrans[0]
    }

    if (precisTranslated) {
      precisVM.translation = precisTranslated
      precisVM.languages = this.getBaseLanguages()

      precisVM.dateString = new Date(
        precisVM.precis.decisionDate,
      ).toLocaleString('fr-FR', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      })
      const thesaurusList: ThesaurusTranslation[] = []
      precisState.precis.thesaurus.forEach((thesaurus: ThesaurusDto) => {
        const thesaurusTranslations = new Map<string, ThesaurusTranslation>(
          Object.entries(thesaurus.thesaurusTranslations),
        )
        const thesaurusTranslated = thesaurusTranslations.get(selectedLang)
        if (thesaurusTranslated) {
          thesaurusList.push({
            indexNumber: thesaurus.indexNumber,
            id: thesaurusTranslated.id,
            text: thesaurusTranslated.text,
            note: thesaurusTranslated.note,
            languageCode: thesaurusTranslated.languageCode,
            thesaurus: thesaurusTranslated.thesaurus,
          })
        }
      })
      precisVM.thesaurus = thesaurusList
      const alphaList: string[] = []
      let taxonList: TaxonDto[] = []
      const taxonLists: TaxonDto[][] = []
      precisState.precis.alphaIndex?.forEach((alphaIndex) => {
        const translatedIndexes = alphaIndex.taxon
          .filter((taxon) => taxon.languageCode === selectedLang)
          .map((taxon) => taxon.word)
          .toString()
        const taxonWords = alphaIndex.taxon.filter(
          (taxon) => taxon.languageCode === selectedLang,
        )
        taxonWords.forEach((taxon) => {
          const taxonCopy = Object.assign({}, taxon)
          taxonCopy.alphaIndexId = alphaIndex.id
          taxonList.push(taxonCopy)
        })
        taxonLists.push(taxonList)
        taxonList = []
        if (translatedIndexes.length > 0) {
          alphaList.push(translatedIndexes.replace(/,/g, ', '))
        }
      })
      precisVM.alphaIndex = alphaList
      precisVM.alphaIndexTaxons = taxonList
      precisVM.alphaIndexTaxonsArray = taxonLists.filter((taxonList) => {
        return taxonList.length > 0
      })
    }

    return precisVM
  }

  getBaseLanguages(): LanguageTranslation[] {
    const languages: LanguageTranslation[] = []
    const precisState = this.store.snapshot().precisState as PrecisStateModel

    if (precisState.precis) {
      precisState.precis.languages.forEach((precisLanguage) => {
        languages.push({
          name: '',
          languageTranslationCode: precisLanguage,
          languageCode: '',
        })
      })
    }

    return languages
  }

  highlight(queryWords: string[], highlightType: SearchTypes): any {
    const vm: PrecisViewModel = this.store.snapshot().precisState
      ?.precisViewmodel as PrecisViewModel
    const vmCopy: PrecisViewModel = JSON.parse(JSON.stringify(vm))
    const queryWordsCopy: string[] = Object.assign([], queryWords)
    this.highlightFacade.cleanHighlightIds()
    this.highlightFacade.ids = []

    //Getting only the precis title
    let titleQuery = ''
    const titleQueryIndex = queryWords.indexOf(vmCopy?.translation?.title)
    if (titleQueryIndex !== -1) {
      titleQuery = queryWords[titleQueryIndex]
    }

    //Getting the right preics date
    const preciDate = new Date(vmCopy?.precis?.decisionDate)
    const year = preciDate.toLocaleString('default', {
      year: 'numeric',
    })
    const month = preciDate.toLocaleString('default', {
      month: '2-digit',
    })
    const day = preciDate.toLocaleString('default', {
      day: '2-digit',
    })
    const dateQuery = year + '-' + month + '-' + day
    const dateQueryIndex = queryWords.indexOf(dateQuery)
    if (dateQueryIndex !== -1) {
      queryWordsCopy[dateQueryIndex] = day + '/' + month + '/' + year
    }

    if (highlightType === SearchTypes.ADVANCED) {
      vmCopy.precis.referenceCode = this.highlightFacade.highlightText(
        queryWordsCopy,
        vm?.precis?.referenceCode,
      )
      vmCopy.translation.courtName = this.highlightFacade.highlightText(
        queryWordsCopy,
        vm?.translation?.courtName,
      )
      if (dateQuery !== '') {
        vmCopy.dateString = this.highlightFacade.highlightText(
          queryWordsCopy,
          vmCopy?.dateString,
        )
      }
      vmCopy.translation.decisionNumber = this.highlightFacade.highlightText(
        queryWordsCopy,
        vmCopy?.translation?.decisionNumber,
      )
      if (titleQuery !== '') {
        vmCopy.translation.title = this.highlightFacade.highlightText(
          [titleQuery],
          vm?.translation?.title,
        )
      }
      vmCopy.translation.officialPublication =
        this.highlightFacade.highlightText(
          queryWordsCopy,
          vm?.translation?.officialPublication,
        )
      //Search with thesaurus
      vmCopy.thesaurus.forEach((thesaurusItem) => {
        thesaurusItem.indexNumber = this.highlightFacade.highlightText(
          queryWordsCopy,
          thesaurusItem?.indexNumber,
        )
        thesaurusItem.text = this.highlightFacade.highlightText(
          queryWordsCopy,
          thesaurusItem?.text,
        )
        return thesaurusItem
      })
      vmCopy.translation.headNoteWithLinks = this.highlightFacade.highlightText(
        queryWordsCopy,
        vm?.translation?.headNoteWithLinks,
      )
      vmCopy.translation.summaryWithLinks = this.highlightFacade.highlightText(
        queryWordsCopy,
        vm?.translation?.summaryWithLinks,
      )
      vmCopy.translation.additionnalInformationWithLinks =
        this.highlightFacade.highlightText(
          queryWordsCopy,
          vm?.translation?.additionnalInformationWithLinks,
        )
      vmCopy.translation.crossReferenceWithLinks =
        this.highlightFacade.highlightText(
          queryWordsCopy,
          vm?.translation?.crossReferenceWithLinks,
        )
    }
    if (highlightType === SearchTypes.ALPHA) {
      vmCopy?.alphaIndexTaxonsArray.forEach((alphaIndexTaxons) => {
        alphaIndexTaxons.forEach((taxon) => {
          taxon.word = this.highlightFacade.highlightText(
            queryWordsCopy,
            taxon?.word,
          )
        })
      })
    }

    this.highlightFacade.setHighlightIds(this.highlightFacade.ids)
    return vmCopy
  }
}
